@import "~@angular/material/theming";
@mixin yo-theme($theme) {
  // $primary: map-get($theme, primary);
  // $accent: map-get($theme, accent);
  // $warn: map-get($theme, warn);
  // $foreground: map-get($theme, foreground);
  // $background: map-get($theme, background);

  /*
    Customization variables
  */

  $form-field-color: map-get($foreground, base);
  $form-field-background: map-get($foreground, text);
  $form-field-background-opacity: 0.2;
  $form-field-padding: 0.425em;
  $form-field-padding-infix: 0.4375em 0;

  $form-field-label-mat-top-offset: 1.28125em;
  $form-field-error-color: mat.get-color-from-palette($warn);

  $form-input-target-elements: ".mat-input-element, .mat-select, .stripe-card-element > .StripeElement";

  $borderRadius: 0.3em;
  /* Global style definition  */
  color: map-get($foreground, text);

  // Material Non Exposed Variables
  $md-xsmall: "max-width: 600px";
  $md-small: "max-width: 960px";
  $md-large: "max-width: 1280px";
  $md-xlarge: "max-width: 1980px";

  /*a {
    color: mat-color($primary);
    text-decoration: none;
  }*/

  .mat-form-field {
    margin-bottom: 1em;

    .mat-form-field-wrapper {
      padding: 0;

      #{$form-input-target-elements} {
        color: $form-field-color;
        background: rgba($form-field-background, $form-field-background-opacity);
        border: 2px transparent solid;
        border-radius: $borderRadius;
        padding: $form-field-padding;
        font-size: 1em;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        background-position: center left;
        background-repeat: no-repeat;
        box-sizing: border-box;
        transition: background-color $swift-ease-out-timing-function $swift-ease-out-duration,
          border-color $swift-ease-out-timing-function $swift-ease-out-duration;

        &:focus {
          color: mat.get-color-from-palette($primary);
          border-color: mat.get-color-from-palette($primary, darker);
          background: rgba(mat.get-color-from-palette($primary), $form-field-background-opacity);
        }
      }

      .mat-form-field-prefix {
        bottom: 0.5em;

        button[class*="mat-"][class*="-button"] {
          font-size: 1.5em;
        }
      }

      .mat-form-field-suffix {
        bottom: 1.7em;
        button[class*="mat-"][class*="-button"] {
          font-size: 1.6em;
        }
      }

      > .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-label {
        // always floats
        transform: translateY(-$form-field-label-mat-top-offset) scale(0.75) perspective(100px) translateZ(0.001px);
      }

      .mat-form-field-label-wrapper {
        .mat-icon {
          width: 1em;
          height: 1em;
          vertical-align: top;
          font-size: 1.3em;
        }
      }

      .mat-form-field-subscript-wrapper {
        opacity: 0.6;
        margin-top: 0.54166667em;
        top: calc(100% - 1em);

        mat-error.prefixed-field {
          margin-left: 3em;
        }
      }
    }

    // Floating state
    &.mat-form-field-should-float {
      .mat-form-field-label {
        top: $form-field-label-mat-top-offset;
        left: 0;
      }
    }

    // Disabled state
    &.mat-form-field-disabled .mat-form-field-wrapper {
      #{$form-input-target-elements} {
        opacity: 0.7;
      }
    }

    // Error state
    &.mat-form-field-invalid .mat-form-field-wrapper {
      #{$form-input-target-elements} {
        border-color: $form-field-error-color;
        background: rgba($form-field-error-color, $form-field-background-opacity);
        color: $form-field-color;
      }

      .mat-form-field-label {
        color: $form-field-error-color;
      }
    }

    // Suffix button in the input
    &.suffix-button-attached-to-input .mat-form-field-wrapper {
      .mat-form-field-suffix {
        bottom: 0em;
        position: absolute;
        right: 0;
        button[class*="mat-"][class*="-button"] {
          line-height: 1.75em;
          font-size: 1em;
          bottom: 0.7em;
          margin: 0 0.2em;
          z-index: 1;
        }
      }
    }

    .mat-tab-nav-bar & {
      margin-bottom: 0;

      .mat-form-field-prefix {
        bottom: 0.1em;
        margin-right: 0.5em;
      }

      .mat-form-field-infix {
        padding: 0;
        border-top: none;
      }
    }
  }

  // CHECKBOX / RADIO
  .mat-checkbox,
  .mat-radio-button {
    color: $form-field-color;
  }

  // BUTTONS
  button[class*="mat-"][class*="-button"] {
    // border-radius: 3em;

    &.mat-raised-button,
    &.mat-stroked-button,
    &.mat-flat-button {
      padding: 0 1.15em;
      margin: 0 0.65em;
      min-width: 3em;
      line-height: 36.4px;
      font-weight: normal;
    }

    &.mat-stroked-button {
      border-width: 2px;
      border-color: currentColor;
      box-sizing: border-box;
      &[color="secondary"] {
        color: $secondary;
        border-color: $secondary;
      }
    }

    &.mat-raised-button[color="secondary"] {
      background-color: $secondary;
      color: mat.get-color-from-palette($primary, default-contrast);
      &[disabled] {
        color: mat.get-color-from-palette($foreground, disabled-button);
        background-color: mat.get-color-from-palette($background, disabled-button);
      }
    }

    &.mat-icon-button {
      &[disabled] {
        mat-icon.mat-icon {
          color: rgba(255, 255, 255, 0.3);
        }
      }

      mat-icon.mat-icon {
        color: mat.get-color-from-palette($primary);
      }
    }
  }
  // TOGGLE-BUTTONS
  .mat-button-toggle-group.input-like {
    margin: 0 0 (1em+0.4375em) 0;
    .mat-button-toggle-label-content {
      line-height: 2em;
    }
  }

  // TABS
  .mat-tab-group {
    &.mobile {
      .mat-tab-header {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: #141414;
        border-radius: $borderRadius;
      }
    }

    &.header-less {
      .mat-tab-header {
        display: none;
      }
    }

    &.custom-tabs {
      .mat-tab-header {
        .mat-tab-list {
          //justify-content: center;
          .mat-tab-labels {
            justify-content: center;
          }
        }
      }
    }
  }

  &.mat-bottom-sheet-container {
    background-color: mat.get-color-from-palette($background, background);
  }

  // FAB Button
  .fab-actions {
    position: fixed;
    bottom: 6.1em;
    right: 1.3em;
    z-index: 99;
    width: 56px;
    margin-right: auto;

    [tooltip] {
      position: relative;
    }

    [tooltip]::before {
      content: attr(tooltip-content);
      display: block;
      position: absolute;
      top: 50%;
      right: calc(100% + 2em);
      padding: 0em 2em;
      text-align: center;
      text-transform: none;
      font-size: 1em;
      border-radius: $borderRadius;
      background: rgba(mat.get-color-from-palette($foreground, base), 0.8);
      opacity: 1;
      transform: translate(20px, -50%);
      z-index: 1;
      color: mat.get-color-from-palette($foreground, elevation);
    }

    // [tooltip]:hover::before {
    //   opacity: 1;
    //   transform: translate(0, -50%);
    // }

    mat-tooltip-component {
      transform: translate(-10em, 2.5em);

      .mat-tooltip.tooltip-left {
        width: 7em;
        // transform: translate(-10em, 2.5em) !important;
        margin: 0;
      }
    }
  }

  // Typography
  .color-secondary {
    color: $secondary;
  }

  .grid-container {
    display: flex;
    flex-direction: row;

    &.wrap {
      flex-wrap: wrap;
    }
  }

  &.card-holder {
    width: 100%;
  }
}
