/* You can add global styles to this file, and also import other style files */
/* @import "~@angular/material/prebuilt-themes/purple-green.css"; */
@import url("https://fonts.googleapis.com/css?family=Muli");

@import "~ngx-sharebuttons/themes/default/default-theme";
.sb-wrapper {
  font-size: 0.8125em !important;
  // Content wrapper
  min-width: 3em;
  /*.sb-content {
    // Icon wrapper
    .sb-icon {
    }
    // Text wrapper
    .sb-text {
    }
  }*/
}

html,
body,
mat-sidenav-container {
  height: 100%;
  margin: 0;
}
// body { margin: 0; font-family: 'Roboto', sans-serif; background-color: rgb(32,32,32); }
// mat-sidenav-content{
//   background-color:rgb(32,32,32);
// }

#hubspot-messages-iframe-container.widget-align-right {
  visibility: hidden;
  width: 70px;
  height: 70px;

  &.visible {
    visibility: visible;
  }
}

.logged-menu + * {
  button {
    div.account {
      display: flex;
      flex-direction: row;
      align-items: center;

      span.labels {
        display: flex;
        flex-direction: column;

        .label {
          line-height: 14px;
          margin-bottom: 2px;
        }

        .email {
          font-size: 8px;
          line-height: 8px;
          height: 10px;
          opacity: 0.5;
        }
      }
    }
  }
}

.checkout-panel-custom mat-dialog-container {
  /*background-color: #FFFFFF;*/
  border-radius: 12px;
  position: relative;
  overflow: visible;
}

.manage-panel-custom mat-dialog-container {
  border-radius: 12px;
  position: relative;
  overflow: visible;
  // background-color: #303030;
}

.yo-no-padding-dialog .mat-dialog-container {
  padding: 0;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
  color: white;
}

a:active {
  text-decoration: underline;
  color: white;
}
