@use '@angular/material' as mat;
@import 'theme-config.scss';
@import 'video.js/dist/video-js.min.css';
$yo-videojs-player-asset-path: "/assets/player";
@import '../projects/yo-videojs-player/src/styles/videojs-custom-style.scss';
@import 'yo-theme.scss';
// Plus imports for other components in your app.

// This fixes the issue of long checkbox labels not warping. Should be present
// before mat-core()
// https://github.com/angular/material2/issues/8416#issuecomment-346380860
.mat-checkbox-layout, .mat-radio-label {
  white-space: normal !important;
}

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500');

// Compute font config
@include mat.core($fontConfig);

// Theme Init
/*@include angular-material-theme($theme);
.theme-alternate {
  @include angular-material-theme($altTheme);
}*/
@include mat.all-component-themes($theme);
.yo-theme {
  @include yo-theme($theme);
}

// Create the alternate theme based on Gray palette using yo-primary as accent
// The alternate style can be selected by adding the "light-theme" class
/*.light-theme {
  @include angular-material-theme($altTheme);
  .yo-theme {
    @include yo-theme($altTheme);
  }
}*/

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px;
  font-weight: normal;
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Two Tone', 'Material Icons';   
  .mat-badge-content {
    font-family: 'Montserrat';
  }
}

.backdrop-custom {
  background-color: rgba(mat.get-color-from-palette($background, background), 0.7);
}
