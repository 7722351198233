@use '~@angular/material' as mat;
$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 400, 'Montserrat', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Montserrat', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Montserrat', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Montserrat', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Montserrat', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'Montserrat', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Montserrat', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Montserrat', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Montserrat', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Montserrat', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Montserrat', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Montserrat', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Montserrat', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.54);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

mat.$light-theme-foreground-palette: (
  base:              black,
  divider:           rgba(black, 0.12),
  dividers:          rgba(black, 0.12),
  disabled:          rgba(black, 0.38),
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     rgba(black, 0.38),
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         rgba(black, 0.38),
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

mat.$dark-theme-foreground-palette: (
  base:              $light-text,
  divider:           rgba(white, 0.12),
  dividers:          rgba(white, 0.12),
  disabled:          rgba(white, 0.5),
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     rgba(white, 0.5),
  elevation:         black,
  hint-text:         rgba(white, 0.5),
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

mat.$light-theme-background-palette: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           rgba(black, 0.12),
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #333333;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           rgba(white, 0.12),
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);


// Theme Config
body {
  --primary-color: #1ed761;
  --primary-lighter-color: #bcf3d0;
  --primary-darker-color: #12c745;
  --text-primary-color: #{rgba(black, 0.87)};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{rgba(black, 0.87)};
}

$mat-primary: (
  main: #1ed761,
  lighter: #bcf3d0,
  darker: #12c745,
  200: #1ed761, // For slide toggle,
  contrast : (
    main: rgba(black, 0.87),
    lighter: rgba(black, 0.87),
    darker: rgba(black, 0.87),
  )
);

body {
  --accent-color: #ececec;
  --accent-lighter-color: #f9f9f9;
  --accent-darker-color: #e4e4e4;
  --text-accent-color: #{rgba(black, 0.87)};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{rgba(black, 0.87)};
}

$mat-accent: (
  main: #ececec,
  lighter: #f9f9f9,
  darker: #e4e4e4,
  200: #ececec, // For slide toggle,
  contrast : (
    main: rgba(black, 0.87),
    lighter: rgba(black, 0.87),
    darker: rgba(black, 0.87),
  )
);

body {
  --warn-color: #ff8244;
  --warn-lighter-color: #ff8244;
  --warn-darker-color: #ff652d;
  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}

$mat-warn: (
  main: #ff8244,
  lighter: #ff8244,
  darker: #ff652d,
  200: #ff8244, // For slide toggle,
  contrast : (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  )
);

// Build theme palettes
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

// Build theme and alternate
$theme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

// Commonly used maps 
// WARNING: Make sure to update those when change from theme to alternateTheme !!
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);
$primary: map-get($theme, primary);
$accent: map-get($theme, accent);
$warn: map-get($theme, warn);
$secondary: #F0E14C;


// Material Non Exposed Variables
$md-xsmall: 'max-width: 600px';
$md-small: 'max-width: 960px';
$md-large: 'max-width: 1280px';
$md-xlarge: 'max-width: 1980px';


$md-toolbar-height-desktop: 64px !default;
$md-toolbar-height-mobile-portrait: 56px !default;
$md-toolbar-height-mobile-landscape: 48px !default;

$md-toolbar-font-size: 20px !default;
$md-toolbar-padding: 16px !default;

$tablet: 768px;
$desktop: 1024px;

@mixin md-up {
  @media screen and (min-width: $tablet) {
    @content;
  }
}
@mixin lg-up {
  @media screen and (min-width: $desktop) {
    @content;
  }
}
@mixin circle-element {
  $circle-size-lg: 120px;
  $circle-size-sm: 96px;

  height: $circle-size-sm;
  width: $circle-size-sm;
  @include md-up() {
    height: $circle-size-lg;
    width: $circle-size-lg;
  }
}

