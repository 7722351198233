/*
  Original source for theme generator from:
  https://codepen.io/heff/pen/EarCt/left/?editors=010
*/
@use "sass:math";

// The color of icons, text, and the big play button border.
$primary-foreground-color: #1ed761; // #fff default
$primary-background-transparency: 0.7;

$primary-background-color: #000;  // #2B333F default

@import "./zoom-component.scss";
@import "./ad-preroll-plugin.scss";

$center-big-play-button: true;

.yo-video-player {
  height: 100%;
  width: 100%;
}
.video-js {

  /* This is used to force using the native size of the video */
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  
  &:not(.vjs-fullscreen) {
    /*min-height: 100%;
    max-height: 70vh;*/
    height: 100%;
  }

  /* The base font size controls the size of everything, not just text.
      All dimensions use em-based sizes so that the scale along with the font size.
      Try increasing it to 15px and see what happens. */
  font-size: 14px;
  font-family: 'Roboto', sans-serif;

  /* The main font color changes the ICON COLORS as well as the text */
  color: $primary-foreground-color;
  background-color: rgba(0,0,0,0.9);
  backdrop-filter: blur(5px);

  /* The "Big Play Button" is the play button that shows before the video plays.
    To center it set the align values to center and middle. The typical location
    of the button is the center, but there is trend towards moving it to a corner
    where it gets out of the way of valuable content in the poster image.*/
  &.vjs-default-skin .vjs-big-play-button {
    /* The font size is what makes the big play button...big.
        All width/height values use ems, which are a multiple of the font size.
        If the .video-js font-size is 10px, then 3em equals 30px.*/
    font-size: 4em;

    /* We're using SCSS vars here because the values are used in multiple places.
        Now that font size is set, the following em values will be a multiple of the
        new font size. If the font-size is 3em (30px), then setting any of
        the following values to 3em would equal 30px. 3 * font-size. */
    $big-play-width: 1.5em;
    /* 1.5em = 45px default */
    $big-play-height: 1.5em;

    line-height: $big-play-height;
    height: $big-play-height;
    width: $big-play-width;
    border: none;
    border-radius: 1.5em;

    @if $center-big-play-button {
      /* Align center */
      left: 50%;
      top: 50%;
      margin-left: - math.div($big-play-width, 2);
      margin-top: - math.div($big-play-width, 2);
    } @else {
      /* Align top left. 0.5em = 15px default */
      left: 0.5em;
      top: 0.5em;
    }
  }

  &.vjs-error .vjs-error-display:before {
    content: " "
  }

  .vjs-control-bar,
  .vjs-big-play-button,
  .vjs-menu-button .vjs-menu-content {
    /* IE8 - has no alpha support */
    background-color: $primary-background-color;
    /* Opacity: 1.0 = 100%, 0.0 = 0% */
    background-color: rgba($primary-background-color, $primary-background-transparency);
  }

  // Make a slightly lighter version of the main background
  // for the slider background.
  $slider-bg-color: lighten($primary-background-color, 33%);

  /* Slider - used for Volume bar and Progress bar */
  .vjs-slider {
    background-color: $slider-bg-color;
    background-color: rgba($slider-bg-color, 0.5);
  }

  /* The slider bar color is used for the progress bar and the volume bar
      (the first two can be removed after a fix that's coming) */
  .vjs-volume-level,
  .vjs-zoom-level,
  .vjs-play-progress/*,
  .vjs-slider-bar*/ {
    background: $primary-foreground-color;
  }

  .vjs-volume-vertical {
    /* IE8 - has no alpha support */
    background-color: $primary-background-color;
    /* Opacity: 1.0 = 100%, 0.0 = 0% */
    background-color: rgba($primary-background-color, $primary-background-transparency);
  }



  /* The main progress bar also has a bar that shows how much has been loaded. */
  .vjs-load-progress {
    /* For IE8 we'll lighten the color */
    background: lighten($slider-bg-color, 25%);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba($slider-bg-color, 0.5);
  }

  /* The load progress bar also has internal divs that represent
      smaller disconnected loaded time ranges */
  .vjs-load-progress div {
    /* For IE8 we'll lighten the color */
    background: lighten($slider-bg-color, 50%);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba($slider-bg-color, 0.75);
  }

  &.vjs-waiting,
  &.vjs-seeking,
  &.vjs-autotrack-switching {

    .vjs-big-play-button {
      display: none
    }

    $primary-background-transparency: 0.5;
    $secondary-background-color: $primary-foreground-color;

    > div.vjs-loading-spinner {

      color: $primary-foreground-color;
      opacity: 1;
      background: transparent;
      border: 0;
      box-sizing: border-box;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      padding: 0;

      /*&:before, &:after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 50%;
        height: 50%;
        border-radius: 50%;
        border : 2px solid currentColor;
        box-sizing: border-box;
        opacity: 0;
        top: 25%;
        left: 25%;
        animation: sonar-effect 2s ease-in-out .1s infinite;
        margin: 0;
      }

      &:after {
        animation-delay : 0.7s;
      }*/

      svg {
        opacity: 0.8;
        animation: emitter-effect 2s ease-out infinite;
        path, circle {
          fill: currentColor;
          stroke: none;
        }
      }

      @keyframes emitter-effect {
        0% {
          //opacity: 0.8;
          transform: scale(1);
          //background-color: rgba(255,255,255,0.8);
        }
        5% {
          //opacity: 0.8;
          transform: scale(1);
          //background-color: rgba(255,255,255,0.8);
        }
        20% {
          //opacity: 1;
          transform: scale(1.2);
          color: #FFF;
          //background-color: rgba(255,255,255,1);
        }
        35% {
          //opacity: 0.8;
          transform: scale(1);
          color: currentColor;
          //background-color: rgba(255,255,255,0.8);
        }
        50% {
          //opacity: 1;
          transform: scale(1.2);
          color: #FFF;
          //background-color: rgba(255,255,255,1);
        }
        65% {
          transform: scale(1);
          //opacity: 0.8;
          //background-color: rgba(255,255,255,0.8);
        }
        100% {
          transform: scale(1);
          opacity: 0.8;
        }
      }

      @keyframes sonar-effect {
        0% {
          opacity: 0;
          transform: scale(0.5);
          color: #FFF;
          //border-color: rgba(255,255,255,0);
        }
        10% {
          opacity: 0.8;
          //border-color: rgba(255,255,255,0.8);
        }
        70% {
          transform: scale(3);
          color: currentColor;
          opacity: 0;
          //border-color: rgba(255,255,255,0);
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
  div.vjs-text-track-display {
    display: none;
  }
}

/** Chrome cast plugin styles */

$yo-videojs-player-asset-path: "." !default;
$icon-chromecast--default: $yo-videojs-player-asset-path+'/images/ic_cast.png';
$icon-chromecast--hover: $yo-videojs-player-asset-path+'/images/ic_cast.png';
$icon-chromecast-casting: $yo-videojs-player-asset-path+'/images/ic_cast_connected.png';
$icon-chromecast-casting--hover: $yo-videojs-player-asset-path+'/images/ic_cast_connected.png';

@import "~@silvermine/videojs-chromecast/src/scss/videojs-chromecast";
.vjs-chromecast-button .vjs-icon-placeholder {
  width: 1.5em;
  height: 1.5em;
}

.vjs-tech-chromecast .vjs-tech-chromecast-poster {
  width: 160px;
  height: 90px;
  background: url($icon-chromecast-casting) no-repeat center;
  img.vjs-tech-chromecast-poster-img {
    display: none;
  }
  &::after {
    display: none;
  }
}