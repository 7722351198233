/*
Styles for ad plugins.
*/

$ad-color: #ffe400;

.vjs-ad-playing.vjs-ad-playing .vjs-progress-control {
  pointer-events: none;
}

// Make the progress bar yellow for ads
.vjs-ad-playing.vjs-ad-playing .vjs-play-progress {
  background-color: $ad-color;
}

// Hide the captions button during ad playback
.vjs-ad-playing .vjs-captions-button {
  display: none;
}

// Hide the audio track button during ad playback
.vjs-ad-playing .vjs-audio-button {
  display: none;
}

/*
Hide BPB and poster while ad loading and resuming after an ad.
*/
.vjs-ad-loading .vjs-big-play-button,
.vjs-ad-loading .vjs-poster,
.vjs-ad-content-resuming .vjs-big-play-button,
.vjs-ad-content-resuming .vjs-poster {
  display: none;
}

.preroll-skip-button,
.advertisement-box,
.remaining-time {
  display: block;
  position: absolute;
  padding: 10px 6px 8px 10px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: auto;
  background-color: rgba(0, 0, 0, .5);
  color: #aaa;
  font-size: 18px;
  font-style: italic;
  line-height: normal;
  text-align: center;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, .35);
  border-right: none;
}

.preroll-skip-button.enabled {
  cursor: pointer;
  color: #fff;
}

.advertisement-box {
  right: auto;
  left: 0;
  font-style: normal;
  border-right: 1px solid rgba(255, 255, 255, .35);
  border-left: none;
}

.preroll-skip-button.enabled:hover {
  cursor: pointer;
  background: #333;
}

.preroll-blocker {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

div.banner-ad {
  max-width: 80%;
  width: 728px;
  max-height: 90px;
  position: absolute;
  /*The control bar is 3em, so make sure we have some space there */ 
  bottom: 3.25em;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border: 2px solid $ad-color;
  background-color: $ad-color;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  span.ad-text {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $ad-color;
    font-size: 0.5em;
    color: #000;
    padding: 0.25em;
  }
}